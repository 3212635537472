import { computed, ComputedRef, watch } from 'vue';
import useSWRV from 'swrv';
import { useStore } from 'vuex';

import { authApiGet } from '@/api/api';
import { useAuth } from '@/use/useAuth';
import { normaliseCmsImage } from '@/components/ResponsiveImage/utils';
import type { ResponsiveImageDTO, ResponsiveImageSet } from '@/types';

type UseCmsFlexParams = {
  slug: string;
};
type UseCmsFlexReturns = {
  data: ComputedRef<NormalisedCmsData>;
};

type AvailableTypes = 'strings' | 'image' | 'media';
type PageContentItemDTO = {
  id: string;
  type: AvailableTypes;
  value: {
    obj_id: string;
    content: string;
    image: ResponsiveImageDTO & { title: string };
    video: { id: string; download_url: string; type: 'media' };
  };
};
type CmsDataDTO = {
  status: number;
  items: {
    page_content: PageContentItemDTO[];
  }[];
};

type NormalisedDataItem = {
  type: AvailableTypes;
  content: string;
  image?: ResponsiveImageSet;
  video?: { id: string; url: string };
};
export type NormalisedCmsData = Record<string, NormalisedDataItem>;

const itemMapper = ({
  value,
  type,
}: PageContentItemDTO): NormalisedDataItem => {
  return {
    type: type,
    content: value?.content || '',
    image: value?.image
      ? normaliseCmsImage({ alt: value.image.title, image: value.image })
      : null,
    video: value?.video
      ? { id: value.video.id, url: value.video.download_url }
      : null,
  };
};

export const useCmsFlex = ({ slug }: UseCmsFlexParams): UseCmsFlexReturns => {
  const store = useStore();
  const handleError = (err) => store.commit('PUSH_ERROR', err);

  const { headers } = useAuth();
  const { data, error } = useSWRV<CmsDataDTO>(
    `cms-flexpages-${slug}`,
    () => authApiGet(`cms/flexpages/${slug}`)(headers.value),
    { revalidateOnFocus: false }
  );

  watch(error, handleError);

  const normalisedCmsData = computed<NormalisedCmsData>(() => {
    return (
      data.value &&
      data.value.items[0].page_content.reduce(
        (acc, item) => ({
          ...acc,
          [item.value.obj_id]: itemMapper(item),
        }),
        {}
      )
    );
  });

  return {
    data: normalisedCmsData,
  };
};

export const getContentFragments = (content: string): string[] => {
  return content?.split('<hr/>') || [];
};

export const convertHtmlToText = (html: string): string => {
  if (!html) return '';
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const decodeHtmlEntities = (html: string): string => {
  if (!html) return '';
  const textarea = document.createElement('textarea');
  textarea.innerHTML = html;
  return textarea.value || '';
};
